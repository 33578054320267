import React, { ReactNode } from 'react';
import './summary-table.scss';

type RowData = {
    key: ReactNode;
    value: ReactNode;
};

type Props = {
    data: RowData[];
};

export const SummaryTable: React.FC<Props> = ({ data }: Props) => {
    return (
        <table className='summary-table'>
            <tbody>
                {data.map((row, index) => {
                    if (!row.value || row.value === '') {
                        return <React.Fragment key={index} />;
                    }

                    return (
                        <tr key={index}>
                            <td>
                                <span>{row.key}</span>
                            </td>
                            <td></td>
                            <td>
                                <span>{row.value}</span>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};
