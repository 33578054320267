import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Space } from 'antd';
import { saveAs } from 'file-saver';
import React, { useEffect } from 'react';
import './result-view.scss';
import { GeneratePdfValues } from '../../../lib/shared/types';
import axios, { AxiosRequestConfig } from 'axios';
import { backendUrl } from '../../../environment';

type Props = {
    processNumber?: string | undefined;
    returnToLandingPage: () => void;
    downloadParameters?: GeneratePdfValues;
};

export const ResultView: React.FC<Props> = ({
    processNumber,
    returnToLandingPage,
    downloadParameters,
}: Props) => {
    const axiosConfig: AxiosRequestConfig = {};
    axiosConfig.baseURL = backendUrl;
    const httpClient = axios.create(axiosConfig);

    const sendEmail = async (): Promise<void> => {
        try {
            await httpClient.post('api/mail-pdf', downloadParameters);
        } catch {
            return;
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        if (downloadParameters) {
            sendEmail();
        }
    }, []);

    const download = async (): Promise<void> => {
        try {
            const response = await httpClient.post('api/generate-pdf', downloadParameters, {
                responseType: 'blob',
            });

            if (!response || !response.data || response.status !== 200) {
                return;
            }

            const contentDisposition: string = response.headers['content-disposition'];

            if (typeof contentDisposition === 'string') {
                const filename = contentDisposition
                    ?.split(';')
                    ?.find((n: string) => n.includes('filename='))
                    ?.replace('filename=', '')
                    ?.replaceAll('"', '')
                    ?.trim();

                saveAs(response.data, filename);
            }
        } catch {
            return;
        }
    };

    return (
        <Card className='view-card'>
            <Space className='result-content' direction='vertical'>
                <h2 className='result-primary'>{'Vielen Dank für Ihren Versicherungsantrag.'}</h2>
                <h3 className='result-secondary'>{`Ihre Vorgangsnummer ${processNumber}`}</h3>
                {downloadParameters ? (
                    <span>
                        {'Wir haben den Antrag an Ihre Email versandt oder Sie können den Antrag '}
                        <button className='result-link-button' onClick={download}>
                            {'hier'}
                        </button>
                        {' herunterladen und ausdrucken.'}
                    </span>
                ) : (
                    <span>
                        {
                            'Ihr Antrag ist bei uns eingegangen und Sie erhalten in Kürze die eVB-Nummer zu Ihrem Fahrzeug und alle relevanten Unterlagen zu Ihrer neuen Kfz-Versicherung.'
                        }
                    </span>
                )}
                <span>{'Bei weiteren Fragen erreichen Sie uns per'}</span>
                <span>
                    {'Email '}
                    <a href='mailto:kundenbetreuung@jlr-insurance.de'>
                        <b>{'kundenbetreuung@jlr-insurance.de'}</b>
                    </a>
                </span>
                <span>{'oder'}</span>
                <span>
                    {'Telefon '}
                    <b>{'+49 (0)6173 33 66 99 2'}</b>
                    {' von 08:00 Uhr - 18:00 Uhr.'}
                </span>
                <span>{'Geben Sie hierbei bitte kurz Ihre Vorgangsnummer an.'}</span>
                <br />
                <span>{'Beste Grüße'}</span>
                <span>{'Ihr JLR Insurance Services-Team!'}</span>
                <br />
                <div className='result-button'>
                    <Button
                        className='navigation-button'
                        type='primary'
                        onClick={returnToLandingPage}
                    >
                        <ArrowLeftOutlined className='arrow-left' />
                        {'Zurück zur Startseite'}
                    </Button>
                </div>
            </Space>
        </Card>
    );
};
