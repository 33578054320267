export const dateFormat = [
    'DD.MM.YYYY',
    'D.MM.YYYY',
    'DD.M.YYYY',
    'D.M.YYYY',
    'DD.MM.YY',
    'D.MM.YY',
    'DD.M.YY',
    'D.M.YY',
];
