import React from 'react';

export const RequiredNotice: React.FC = () => {
    return (
        <span className='required-notice'>
            {'mit '}
            <span className='form-field-required'>{'*'}</span>
            {' gekennzeichnete Felder sind Pflichtfelder'}
        </span>
    );
};
