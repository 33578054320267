import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Card, Input } from 'antd';
import React, { ReactNode, useState } from 'react';
import { FormField, FormSection, RequiredNotice } from '../../components';

const errorMessage = (
    <span>
        {'Bitte geben Sie eine gültige E-Mail-Adresse ein und versuchen Sie es erneut.'}
        <br />
        <br />
        {
            'Sie benötigen Unterstützung? Rufen Sie uns an +49(0)6173 33 66 99 2 oder schreiben Sie uns '
        }
        <a href='mailto:kundenbetreuung@jlr-insurance.de'>{'kundenbetreuung@jlr-insurance.de'}</a>
    </span>
);

type Props = {
    onFormSubmit: (values: FormValues) => boolean | Promise<boolean>;
    gotoDealerLogin: () => void;
};

type FormValues = {
    email: string | undefined;
};

export const CustomerLoginView: React.FC<Props> = ({ onFormSubmit, gotoDealerLogin }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [values, setValues] = useState<FormValues>({
        email: undefined,
    });
    const [error, setError] = useState<ReactNode>(null);

    const onChange = (element: HTMLInputElement): void => {
        setValues({ ...values, email: element.value });
    };

    const onFinish = async (): Promise<void> => {
        setIsLoading(true);
        if (!values.email || !(await onFormSubmit(values))) {
            setError(errorMessage);
        } else {
            setError(null);
        }
        setIsLoading(false);
    };

    return (
        <Card className='view-card width-limited-card login-view'>
            <h3>{'Bitte geben Sie zur Verifizierung Ihre E-Mail ein:'}</h3>
            <FormSection>
                <FormField label='E-MAIL-ADRESSE' required error={error} style='full-width'>
                    <Input
                        className='email-input'
                        name='email'
                        maxLength={80}
                        value={values.email}
                        onChange={(e) => onChange(e.target)}
                        status={error ? 'error' : ''}
                    />
                </FormField>
                <div>
                    <span>
                        {'Sind Sie JAGUAR LAND ROVER Händler? Klicken Sie bitte '}
                        <u
                            role='presentation'
                            onClick={gotoDealerLogin}
                            style={{ cursor: 'pointer' }}
                        >
                            {'hier'}
                        </u>
                    </span>
                </div>
            </FormSection>
            <RequiredNotice />
            <div className='flex-container'>
                <div style={{ flexGrow: 1 }}></div>
                <Button
                    className='navigation-button'
                    type='primary'
                    onClick={onFinish}
                    loading={isLoading}
                >
                    <span>
                        {'Anmelden'}
                        <ArrowRightOutlined className='arrow-right' />
                    </span>
                </Button>
            </div>
        </Card>
    );
};
